<template>
  <ol
    id="submenu"
    data-el="ui-menu-subpages"
    :data-variant="props.variant"
    class="relative z-[1] flex pb-1"
    :class="[
      {
        'text-base md:text-lg xl:text-xl': variant === MenuVariantEnum.DEFAULT,
        'text-base xl:text-lg': variant === MenuVariantEnum.SMALL,
        '!gap-0 text-base': variant === MenuVariantEnum.MEDIUM,
        'flex-wrap text-sm xl:text-lg': variant === MenuVariantEnum.RECTANGLE,
        'justify-between gap-6': variant !== MenuVariantEnum.TABS,
        '!justify-start !gap-0': variant === MenuVariantEnum.FULL,
        'justify-between gap-6 !pb-0 lg:!justify-end': variant === MenuVariantEnum.BIG,
        'opacity-30 grayscale': props.disabled,
      },
    ]"
  >
    <li
      v-for="(menuItem, index) in menuItems"
      :id="menuItem?.id ?? ''"
      :key="menuItem?.id ?? index"
      class="group relative inline-block cursor-pointer whitespace-nowrap text-center"
      :data-active="menuItem.active"
      :class="[
        props.listItemClass,
        {
          'opacity-30 grayscale': menuItem.disabled,
          'after:absolute after:inset-x-0 after:-bottom-1.5 after:mx-auto after:size-0 after:border-x-4 after:border-t-4 after:border-x-transparent after:border-t-primary':
            menuItem.active,
          'min-w-36 xl:min-w-0': variant === MenuVariantEnum.SMALL,
          'flex-1 rounded-lg border border-blue-100 after:!content-none':
            props.variant === MenuVariantEnum.RECTANGLE,
          '!border-2 bg-blue-200':
            props.variant === MenuVariantEnum.RECTANGLE && menuItem.active,
          '!border-b-2 text-base sm:px-2 sm:text-xl': props.variant === MenuVariantEnum.TABS,
          'before:absolute before:bottom-0 before:left-1/2 before:h-0.5 before:w-0 before:-translate-x-1/2 before:bg-blue-450 before:transition-all hover:before:w-full':
            (props.variant === MenuVariantEnum.DEFAULT && !menuItem.active),
          'border-b-2 border-b-primary':
            (props.variant === MenuVariantEnum.DEFAULT
              || props.variant === MenuVariantEnum.TABS
              || props.variant === MenuVariantEnum.SMALL
              || props.variant === MenuVariantEnum.FULL
              || props.variant === MenuVariantEnum.MEDIUM
            )
            && menuItem.active,
          'w-full !border-b-2 border-blue-450 text-base sm:px-2 sm:text-lg': props.variant === MenuVariantEnum.FULL,
          'flex-auto !border-b-2 border-blue-450': props.variant === MenuVariantEnum.MEDIUM,
          'bg-blue-450': props.variant === MenuVariantEnum.FULL && menuItem.active,
          'border-b-8 border-b-primary after:border-t-transparent':
            (props.variant === MenuVariantEnum.BIG && menuItem.active),
        },
      ]"
      itemprop="itemListElement"
      itemscope
      itemtype="https://schema.org/ListItem"
    >
      <component
        :is="isNuxtLink"
        v-slot="{ navigate = () => null, href = '' }"
        no-prefetch
        :to="menuItem.link"
        custom
      >
        <template v-if="menuItem.link || props.link">
          <button
            :href="href"
            class="absolute z-10 inline-block w-full p-2.5 text-primary transition-colors"
            :class="[
              {
                'px-4': variant === MenuVariantEnum.RECTANGLE,
                'cursor-not-allowed': menuItem.disabled,
                'opacity-0': menuItem.active,
                'pb-3': variant === MenuVariantEnum.MEDIUM,
              },
            ]"
            rel="prefetch"
            itemprop="name"
            data-test="ui-menu-subpages-tab"
            @click.prevent="
              menuItem.disabled || props.disabled
                ? ''
                : onClick(menuItem, navigate)
            "
          >
            {{ menuItem.title }}
          </button>
          <!-- In order to prevent content jump when active element gains 'font-bold' - add
               additional span element which changes opacity -->
          <span
            itemprop="name"
            class="relative inline-block w-full p-2.5 font-bold text-primary transition-colors"
            :class="{
              'opacity-100': menuItem.active,
              'opacity-0': !menuItem.active,
            }"
          >
            {{ menuItem.title }}
          </span>
        </template>

        <span
          v-else
          itemprop="name"
        >{{ menuItem.title }}</span>
      </component>
    </li>
  </ol>
</template>

<script setup lang="ts">
import { computed, resolveComponent } from 'vue'
import type { MenuItem, MenuVariant } from './UiMenuSubpages.types'
import { MenuVariantEnum } from './UiMenuSubpages.types'

interface UiMenuSubpages {
  link?: boolean
  nativeLink?: boolean | undefined
  disabled?: boolean
  menu: MenuItem[]
  variant?: MenuVariant
  listItemClass?: string[]
}

const props = withDefaults(defineProps<UiMenuSubpages>(), {
  link: true,
  nativeLink: false,
  disabled: false,
  variant: 'default',
  listItemClass: () => [],
})

const emit = defineEmits<{
  (e: 'click', link: MenuItem): void
}>()

const menuItems = computed((): MenuItem[] =>
  props.menu.filter(item => !item?.visible),
)

const isNuxtLink = computed(() =>
  props.link ? resolveComponent('nuxt-link') : 'div',
)

function onClick(item: MenuItem, cb: (...args: any[]) => void) {
  emit('click', item)
  if (item.link) {
    cb(item.link)
  }
}
</script>
