export interface MenuItem {
  title: string
  id?: string | number
  link?: string
  disabled?: boolean
  active: boolean
  target?: string
  current?: number
  spacer?: number
  hasSubpages?: number
  visible?: boolean
}

export enum MenuVariantEnum {
  DEFAULT = 'default',
  SMALL = 'small',
  MEDIUM = 'medium',
  BIG = 'big',
  RECTANGLE = 'rectangle',
  TABS = 'tabs',
  FULL = 'full',
}

export type MenuVariant = `${MenuVariantEnum}`
